$font-style-primary: sans-serif;

body * {
  font-family: $font-style-primary;
  color: $text-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.gif-collection {
  list-style: none;
  width: 100%;
}
.gif-entry {
  box-sizing: border-box;
  margin: 0.5rem;
  img {
    border-radius: 10px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
  &:hover {
    background-color: $lightest-gray;
    cursor: pointer;
  }
}

.active img {
  opacity: 1;
  filter: alpha(opacity=100);
}

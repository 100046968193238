// Detail is a third of the full desktop page.
#detail {
  flex: 1 1 0;
  min-width: 40%;
  max-width: 40%;
  right: 0;
  padding: 20px;
  position: sticky;
  top: 0;
  video,
  video source {
    width: 100%;
  }
}

.heading {
  margin: 0.5rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  * {
    margin-right: 1rem;
  }
}

.body {
  * {
    margin-bottom: 0.75rem;
  }
}

.table {
  width: 100%;

  & .label * {
    margin: 0;
  }
  tr:nth-child(even) {
    background-color: $lightest-gray;
  }
  * td {
    padding: 0.5rem;
  }
}

// Main.scss is for global and top level container styling.
body,
html {
  height: 100%;
  margin: 0;
}
button {
  font-size: 75%;
  padding: 0.5rem;
  display: block;
  text-decoration: none;
  border: solid 1px $dark-gray;
  border-radius: 50px;
  background: white;
  &:hover {
    background-color: $gray;
    color: $text-light;
    cursor: pointer;
  }
}
.is-flex {
  display: flex;
  padding: 1rem;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.is-stretch {
    justify-content: stretch;
    align-items: stretch;
  }
  &.is-col {
    flex-direction: column;
  }
}

#ui-message {
  > * {
    color: $gray;
  }
  &.info * {
    color: darkblue;
  }
  &.alert * {
    color: darkred;
  }
}

#navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  input,
  button {
    padding: 0.5rem 2rem;
    font-size: 100%;
    display: block;

    text-decoration: none;
    border: solid 1px $dark-gray;
    border-radius: 50px;
    background: white;
  }
  form {
    :first-child {
      border-radius: 50px 0 0 50px;
    }
    button {
      &:hover {
        background-color: $gray;
      }
    }
    :last-child {
      border-radius: 0 50px 50px 0;
      border-left: none;
    }
  }
  div > button {
    &:first-child {
      margin-right: 1rem;
    }
    width: 100%;
    background-color: $dark-gray;
    color: $text-light;
    &:hover {
      background-color: $gray;
      color: $text-light;
    }
  }
}

#main {
  flex: 1 1 0;
  left: 0;
  z-index: 0;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
